import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import Spacing from 'frontend/utils/spacing'
import DeployPermissionsButton from '../Header/DeployPermissionsButton'

const BUTTON_STYLE = {
  background: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
  margin: '24px 0',
  padding: Spacing.S3,
}

const TEXT =
  'Click the "Deploy Permissions" button below to grant new users tool access.'

const Wrapper = styled.section({
  paddingLeft: 48,
})

const Title = styled.div({
  color: Colors.PRIMARY,
  fontWeight: 700,
  paddingBottom: 12,
})

const DeploySection = () => {
  return (
    <Wrapper>
      <Title>STEP 3</Title>
      <p>{TEXT}</p>
      <DeployPermissionsButton style={BUTTON_STYLE} />
    </Wrapper>
  )
}

export default DeploySection
