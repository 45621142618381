import { formatDateMonthYearDash } from 'frontend/utils/formatDate'
import { getPolicyStatus } from './getPolicyStatus'

export const formatColumnData = (data: any[]) => {
  return data.map(
    ({
      payer_organization,
      book_of_business,
      instruction,
      products,
      updated_at,
      ...rest
    }) => {
      const {
        filteredProducts,
        productNames,
        productPolicyStatuses,
      } = products.reduce(
        (acc: any, product: any) => {
          if (!product.product) return acc

          const brand_name = product.product?.brand_name || ''
          acc.filteredProducts.push(product)
          acc.productNames.push(brand_name)
          acc.productPolicyStatuses.push(product.policy_status)
          return acc
        },
        {
          filteredProducts: [],
          productNames: [],
          productPolicyStatuses: [],
        }
      )
      const { policyStatus } = getPolicyStatus(productPolicyStatuses)

      const formattedUpdatedAt = formatDateMonthYearDash(updated_at)

      return {
        ...rest,
        assignees:
          instruction &&
          instruction.products &&
          instruction?.products[0]?.assigned_criteria_reviewers,
        project:
          instruction &&
          instruction.products &&
          instruction?.products[0]?.payer_policy_project?.name,
        updated_at: formattedUpdatedAt,
        payer_organization_name: payer_organization.name,
        book_of_business_name: book_of_business.name,
        instruction_status: instruction.instruction_status,
        payer_website_link: instruction.payer_website_link,
        products: filteredProducts,
        product_names: productNames,
        product_policy_status: policyStatus,
      }
    }
  )
}
