import React from 'react'
import { Box } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'

import { LabelCheckbox } from 'frontend/components'
import { useQueryString } from 'frontend/hooks'

import { SectionLabel } from '../SectionLabel'
import { FilterButton } from './FilterButton'
import { InfoButton } from './InfoButton'

interface Props {
  products: any[]
  productsState: any
  setProductsState: any
}

const CHECKBOX_STYLES = {
  paddingBottom: 4,
  marginRight: 4,
  zIndex: 0,
}

const CheckboxLabel = styled.div({ display: 'flex', flexDirection: 'row' })

export const Header = ({
  products,
  productsState,
  setProductsState,
}: Props) => {
  const { shouldShowBulkApproval } = useFlags()
  const { view } = useQueryString()
  const shouldShowCheckbox = shouldShowBulkApproval && view === 'policies'

  const onCheckboxChange = () => {
    setProductsState((prevState: Set<string>) => {
      if (prevState.size > 0) {
        return new Set()
      }

      return products.reduce((acc: Set<string>, { id }: { id: string }) => {
        acc.add(id)
        return acc
      }, new Set())
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <CheckboxLabel>
        {shouldShowCheckbox && (
          <LabelCheckbox
            checked={productsState.size > 0}
            onChange={onCheckboxChange}
            styles={CHECKBOX_STYLES}
          />
        )}
        <SectionLabel label="Products" />
      </CheckboxLabel>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        {/* <FilterButton onClick={onClick} /> */}
        <InfoButton />
      </Box>
    </Box>
  )
}
