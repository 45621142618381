import React from 'react'
import styled from '@emotion/styled'

import { DownloadTutorialLink } from './DownloadTutorialLink'
import { CloseIcon } from './CloseIcon'

const Container = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 48px',
})

const HEADER_LABEL = 'Bulk Create Users'

const Header = () => {
  return (
    <Container>
      <h2>{HEADER_LABEL}</h2>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DownloadTutorialLink />
        <CloseIcon style={{ color: '#455c75' }} />
      </div>
    </Container>
  )
}

export default Header
