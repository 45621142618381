import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useRewriteSearchString } from 'frontend/hooks'

export const CloseIcon = ({ style = {} }) => {
  const rewriteSearchString = useRewriteSearchString()

  const cancel = () => {
    rewriteSearchString({ bulkCreateUsers: undefined })
  }

  return (
    <FontAwesomeIcon
      icon={faTimes as IconProp}
      onClick={cancel}
      size="2x"
      style={{ cursor: 'pointer', marginRight: '16px', ...style }}
    />
  )
}
