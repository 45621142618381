import React, { useState } from 'react'
import { Box } from '@mui/material'

import { useQueryString } from 'frontend/hooks'

import { Header } from './Header'
import { SetBulkStatusButton } from './SetBulkStatusButton'
import { Product } from './Product'

interface Props {
  mostRecentPolicy: any
  policyState: any
  setPolicyState: any
}

const STYLES = {
  marginTop: '24px',
  marginLeft: '12px',
  padding: '24px',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  width: '423px',
}

const ProductsSection = ({
  mostRecentPolicy,
  policyState,
  setPolicyState,
}: Props) => {
  const { product_id } = useQueryString()
  const [productsState, setProductsState]: [Set<string>, any] = useState(
    new Set()
  )
  let products = mostRecentPolicy?.products || []
  if (product_id) {
    products = products.filter(
      (product: any) => product?.product?.id === product_id
    )
  }

  return (
    <Box sx={STYLES}>
      <Header
        products={products}
        productsState={productsState}
        setProductsState={setProductsState}
      />
      <Box
        sx={{
          marginTop: '16px',
        }}
      >
        {products.map((documentProduct: any, index: number) => (
          <Product
            key={index}
            documentProduct={documentProduct}
            policyState={policyState}
            setPolicyState={setPolicyState}
            productsState={productsState}
            setProductsState={setProductsState}
          />
        ))}
      </Box>
      <SetBulkStatusButton
        setPolicyState={setPolicyState}
        productsState={productsState}
        setProductsState={setProductsState}
      />
    </Box>
  )
}

export default ProductsSection
