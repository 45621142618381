import styled from '@emotion/styled'

import { Tab, tabClasses } from '@mui/base/Tab'

const StyledTab = styled(Tab)`
  font-family: 'Inter';
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 700;
  text-align: left;
  background-color: #f1f5fa;
  color: #9daec4;
  width: 113px;
  height: 28px;
  padding: 4px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  :hover {
    background-color: #dde9f4;
    cursor: pointer;
  }
  &.${tabClasses.selected} {
    background-color: #ffffff;
    color: #0668d9;
    :hover {
      background-color: rgba(6, 104, 217, 0.25);
    }
  }
`

export default StyledTab
